import styled from '@emotion/styled';
import firebase from 'firebase/app';
import { findLastIndex } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { isIOS, isSafari } from 'react-device-detect';
import { useInterval } from 'react-use';
import {
    BroadcastPersistentState,
    isScheduledWavepath,
    Session,
    SessionRenderType,
    Wavepath,
} from 'wavepaths-shared/core';
import { getDurationFromScore } from 'wavepaths-shared/domain/sessionScore';

import TypographyV2 from '@/component-library/typography/TypographyV2';
import { GenericErrorBoundary } from '@/components/GenericErrorBoundary';
import { FeedbackButtons } from '@/components/notifications/FeedbackButtons';
import { GlobalSnackbarContext } from '@/components/Snackbar';
import { Features } from '@/features';
import { SessionCacheContext, useSessionCache } from '@/hooks/useAudioCache';
import useUserSubscription from '@/hooks/useUserSubscription';

import LoadingOrb from '../../../common/components/LoadingOrb';
import WavepathsLogo from '../../../images/WavepathBlueLogo.svg';
import { fetchExpirationInfo } from '../../sessions/utils';
import { offeringsForProlong } from '../../subscriptions/data/offerings';
import { AudioPlayer, AudioPlayerContext, AudioPlayerMaker, NULL_AUDIO_PLAYER } from '../AudioPlayerProvider';
import { WaveSelection } from '../autoGuide/waveSelection';
import PlaybackOptionsDialog from '../deviceOptions/PlaybackOptionsDialog';
import { PlayerChoiceContext } from '../deviceOptions/PlayerChoiceContext';
import { Timeline } from '../timeline/Timeline';
import { VolumeMeter } from '../VolumeMeter';
import { DepthStateVisualiser } from './DepthStateVisualizer';
import { PrecomposedGuideHeader } from './PrecomposedGuideHeader';
import { PregenEndOfSessionModal } from './PregenEndOfSessionModal';
import { useSniffedAudioPlayerTypeStreamUrl } from './useSniffedAudioPlayerTypeStreamUrl';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* 20240613 IpadOS 17.5.1 breaking css changes caused overflow - forcing it to height seem to fix */
    height: 100vh;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto 1fr;
    justify-content: center;
`;

const Main = styled.main`
    display: grid;
    justify-content: stretch;
    align-content: stretch;
`;

const Content = styled.div`
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    grid-template-rows: 1fr min-content;
`;

const Center = styled.div`
    display: grid;
    place-content: center;
    justify-items: center;
`;

export const VolumeContainer = styled.div`
    background: rgba(255, 255, 255, 0.5);
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.4);
`;

export const PrecomposedAudioControls = styled.div`
    display: inline-grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 16px;
    padding: 25px;
    justify-content: center;
`;

export const PlayPauseButton = styled.button`
    display: grid;
    place-content: center;
    width: 80px;
    height: 80px;
    margin: 0;
    padding: 0;
    border-radius: 52px;
    border-width: 0;
    background-color: #2b2d3f;
    cursor: pointer;
    transition: background-color 0.15s ease;
    &:hover {
        background-color: #3d3f57;
    }
`;

export const WaveJumpButton = styled.button`
    display: grid;
    place-content: center;
    width: 64px;
    height: 64px;
    margin: 0;
    padding: 0px 4px 0px 0px;
    border-radius: 32px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    cursor: pointer;
    transition: background-color 0.15s ease;
    &:first-of-type {
        padding-right: 4px;
    }
    &:last-of-type {
        padding-left: 4px;
    }
    &:disabled {
        cursor: not-allowed;
    }
    &:disabled svg {
        opacity: 0.5;
    }
    &:hover {
        background-color: rgba(255, 255, 255, 0.9);
    }
    &:disabled:hover {
        background-color: rgba(255, 255, 255, 0.6);
    }
`;

export const AudioControlIconWhite = styled.svg`
    fill: white;
`;

export const AudioControlIconBlack = styled.svg`
    fill: black;
`;

const Bottom = styled.div`
    margin: auto auto;
`;

const BottomContent = styled.div``;

const Logo = styled.img`
    display: block;
    height: 30px;
    margin: 10px;
`;

const FeedbackButtonsPositioned = styled(FeedbackButtons)({
    position: 'absolute',
    bottom: 40,
    right: 40,
    display: 'flex',
    flexDirection: 'row',
});

const MiddleNotificationText = styled.div`
    text-align: center;
    margin-top: 25px;
    padding: 0 20px;
`;

const STATIC_WAVE_SELECTION: WaveSelection = {
    selection: 'none',
};

const STATIC_SET_WAVE_SELECTION = () => undefined;

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const SessionControls = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

export function PrecomposedGuide({
    session,
    firebaseUser,
    playDemoVO,
    isEnabled,
}: {
    session: Session;
    firebaseUser?: firebase.User;
    playDemoVO: boolean;
    isEnabled?: (feature: string) => boolean;
}): JSX.Element {
    const freePaidPathPart = playDemoVO ? 'free' : 'paid';

    //TODO initial state from persistent storage
    const [broadcastState] = useState<BroadcastPersistentState>(
        session.broadcastState || {
            timeline: [{ sessionId: session.id, dspOffset: 0, broadcastOffset: session.score.dspOffset }],
            discarded: [],
        },
    );

    const playerChoiceContext = useContext(PlayerChoiceContext);
    if (playerChoiceContext === undefined) throw new Error('no PlayerChoiceContext');
    const [audioPlayer, setAudioPlayer] = useState<AudioPlayer>(NULL_AUDIO_PLAYER);

    const { streamUrl, playerTypeResolution } = useSniffedAudioPlayerTypeStreamUrl(session);
    const sessionCache = useSessionCache({
        session,
        streamUrl,
        estimatedDurationSec: getDurationFromScore(session.score.wavepaths) / 1000,
    });

    const { setSnackbarContent } = useContext(GlobalSnackbarContext);

    const { isPossibleToGoToPrev, isPossibleToGoToNext, goToPrev, goToNext } = useWaveSeekControls(
        session,
        audioPlayer,
    );

    useEffect(() => {
        console.debug('Broadcast state', broadcastState);
        audioPlayer.actions.unblock();
    }, []);

    const togglePlaying = async () => {
        if (audioPlayer.playerStatus === 'playing') {
            audioPlayer.actions.pause({ reason: 'user' });
        } else {
            await audioPlayer.actions.unblock();
            audioPlayer.actions.play();
        }
    };

    const playHere =
        (playerChoiceContext.whereToPlayMusic === 'thisDevice' ||
            playerChoiceContext.whereToPlayMusic === 'thisAndRemote') &&
        playerChoiceContext.playerChoice === 'browser';

    // We want to keep seek conservatively updating, as it will cause timeline re-rendering
    // for no good reason if it changes.
    const actionsRef = useRef(audioPlayer.actions);
    useEffect(() => {
        actionsRef.current = audioPlayer.actions;
    }, [audioPlayer]);

    const seek = useCallback(
        async (wave: Wavepath, offsetSeconds?: number) => {
            if (isIOS && !playHere) return;

            await actionsRef.current.unblock();
            if (wave.plan?.fromTime !== undefined) {
                actionsRef.current.setTime(wave.plan?.fromTime / 1000 + (offsetSeconds ?? 0));
            }
        },
        [isIOS, playHere],
    );

    useEffect(() => {
        setSnackbarContent(audioPlayer.warning);
    }, [audioPlayer.warning]);

    const { activeOfferingTier } = useUserSubscription(session.userId);
    const hasFreeAccess = isEnabled ? isEnabled(Features.FREE_ACCESS) : false;

    const { isExpired, expirationMessage, prolongMessage } = fetchExpirationInfo(session);
    const isEligibleSubscription =
        hasFreeAccess || (activeOfferingTier && offeringsForProlong.includes(activeOfferingTier));

    const voiceOverStages = useMemo(() => {
        return session.score.voiceover ?? [];
    }, [session.score.voiceover]);

    return (
        <Container>
            {session && playerTypeResolution !== 'unknown' ? (
                <>
                    <AudioPlayerMaker
                        playerTypeResolution={playerTypeResolution}
                        playerProps={{
                            outputDevice: playerChoiceContext.browserPlayerOutputDevice,
                            broadcastIdentifier: session.broadcastIdentifier,
                            renderType: session.renderType,
                            errorContext: 'Pregen Session',
                            mode: 'recording',
                            broadcastState,
                            broadcastElapsedTimeSecs: 0,
                            sessionDuration: Number(session.variableInputs.totalDuration) * 1000 * 60,
                            voiceOverStages,
                            playDemoVO,
                        }}
                        setAudioPlayer={setAudioPlayer}
                    />
                    <AudioPlayerContext.Provider value={audioPlayer}>
                        <HeaderContainer>
                            <SessionCacheContext.Provider value={sessionCache}>
                                <PrecomposedGuideHeader
                                    session={session}
                                    sessionPlaysHere={playHere}
                                    headerCenterText={
                                        <>
                                            {expirationMessage} {isEligibleSubscription ? <br /> : null}{' '}
                                            {isEligibleSubscription ? prolongMessage : ''}
                                        </>
                                    }
                                    totalDurationMs={Number(session.variableInputs.totalDuration) * 1000 * 60}
                                    isExpired={isExpired}
                                />
                            </SessionCacheContext.Provider>
                        </HeaderContainer>

                        <GenericErrorBoundary>
                            <Main>
                                <Content>
                                    <Center>
                                        <TimelineWithCurrentTimeTracking
                                            session={session}
                                            audioPlayer={audioPlayer}
                                            seek={seek}
                                        />
                                        {!isExpired && playHere && (
                                            <>
                                                <PrecomposedAudioControls>
                                                    {audioPlayer.playerStatus !== 'loading' ? (
                                                        <>
                                                            <WaveJumpButton
                                                                aria-label="Jump to previous wave"
                                                                onClick={goToPrev}
                                                                disabled={!isPossibleToGoToPrev}
                                                            >
                                                                <AudioControlIconBlack
                                                                    width="40"
                                                                    height="40"
                                                                    viewBox="0 0 40 40"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M9.16667 30V10H11.9444V30H9.16667ZM30.8333 30L16.2223 20L30.8333 10V30Z" />
                                                                </AudioControlIconBlack>
                                                            </WaveJumpButton>
                                                            <PlayPauseButton onClick={togglePlaying}>
                                                                {audioPlayer.playerStatus === 'playing' ? (
                                                                    <AudioControlIconWhite
                                                                        width="48"
                                                                        height="48"
                                                                        viewBox="0 0 48 48"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path d="M28.25 38V10H36V38H28.25ZM12 38V10H19.75V38H12Z" />
                                                                    </AudioControlIconWhite>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {audioPlayer.playerStatus === 'idle' ||
                                                                audioPlayer.playerStatus === 'paused' ||
                                                                audioPlayer.playerStatus === 'error' ? (
                                                                    <AudioControlIconWhite
                                                                        width="48"
                                                                        height="48"
                                                                        viewBox="0 0 48 48"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path d="M14 38V10L36 24L14 38Z" />
                                                                    </AudioControlIconWhite>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </PlayPauseButton>
                                                            <WaveJumpButton
                                                                aria-label="Jump to next wave"
                                                                onClick={goToNext}
                                                                disabled={!isPossibleToGoToNext}
                                                            >
                                                                <AudioControlIconBlack
                                                                    width="40"
                                                                    height="40"
                                                                    viewBox="0 0 40 40"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path d="M28.0556 30V10H30.8333V30H28.0556ZM9.16667 30V10L23.7778 20L9.16667 30Z" />
                                                                </AudioControlIconBlack>
                                                            </WaveJumpButton>
                                                        </>
                                                    ) : (
                                                        <>Loading...</>
                                                    )}
                                                </PrecomposedAudioControls>
                                                <SessionControls>
                                                    {audioPlayer.isVolumeControllable ? (
                                                        <VolumeContainer>
                                                            <VolumeMeter />
                                                        </VolumeContainer>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </SessionControls>
                                            </>
                                        )}
                                        {isExpired && (
                                            <MiddleNotificationText>
                                                <TypographyV2
                                                    color="grey-700"
                                                    size="text-xl"
                                                    weight="semibold"
                                                    element="p"
                                                >
                                                    This recording has already expired.
                                                    <br />
                                                    Please contact your provider directly if you wish to listen to
                                                    another session.
                                                </TypographyV2>
                                            </MiddleNotificationText>
                                        )}
                                    </Center>
                                    {session.endedAt ? (
                                        <Bottom>
                                            <BottomContent>
                                                <a href="https://www.wavepaths.com" target="_blank'">
                                                    <Logo src={WavepathsLogo} alt="Wavepaths" />
                                                </a>
                                            </BottomContent>
                                        </Bottom>
                                    ) : (
                                        <></>
                                    )}
                                    {!session.endedAt ? <FeedbackButtonsPositioned /> : null}
                                </Content>
                                <DepthStateVisualiser session={session} />
                            </Main>
                            <PlaybackOptionsDialog
                                broadcastIdentifier={session.broadcastIdentifier}
                                isPlayerAppSupported={
                                    /* offline session playback only supported on playerlib, not the old streaming system*/
                                    playerTypeResolution === 'playerlib' ||
                                    session.renderType === SessionRenderType.PREDICTIVE_COMPOSED
                                }
                                isWebPlayerSupported={
                                    session.renderType === SessionRenderType.REAL_TIME_DEPRECATED ||
                                    (!isSafari && !isIOS)
                                }
                                sessionRenderType={session.renderType}
                                freePaidPathPartForPlayerAppLink={freePaidPathPart}
                            />
                        </GenericErrorBoundary>
                    </AudioPlayerContext.Provider>
                </>
            ) : (
                <LoadingOrb />
            )}

            {firebaseUser && (
                <PregenEndOfSessionModal
                    currentUser={firebaseUser}
                    wavepaths={session.score.wavepaths}
                    sessionId={session.id}
                    sessionOwner={session.userId}
                />
            )}
        </Container>
    );
}

const useWaveSeekControls = (session: Session, audioPlayer: AudioPlayer) => {
    const scheduledWavepathStartTimesSeconds = useMemo(() => {
        const scheduledWavepaths = session.score.wavepaths.filter(isScheduledWavepath);
        return scheduledWavepaths.map((p) => (p.plan?.fromTime ?? Number.MAX_SAFE_INTEGER) / 1000);
    }, [session]);
    const [currentWavepathIndex, setCurrentWavepathIndex] = useState(0);
    const [isPossibleToGoToPrev, setIsPossibleToGoToPrev] = useState(false);
    const [isPossibleToGoToNext, setIsPossibleToGoToNext] = useState(false);
    useEffect(() => {
        const interval = setInterval(async () => {
            const currentTimeSecs = (await audioPlayer.getCurrentTimeSecs()) ?? 0;
            const currentWavepathIndex = findLastIndex(scheduledWavepathStartTimesSeconds, (s) => s <= currentTimeSecs);
            setCurrentWavepathIndex(currentWavepathIndex);
            setIsPossibleToGoToPrev(currentWavepathIndex > 0);
            setIsPossibleToGoToNext(currentWavepathIndex < scheduledWavepathStartTimesSeconds.length - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [scheduledWavepathStartTimesSeconds, audioPlayer]);

    const goToPrev = async () => {
        await audioPlayer.actions.unblock();
        if (currentWavepathIndex <= 0) return;
        const prevStartTime = scheduledWavepathStartTimesSeconds[currentWavepathIndex - 1];
        audioPlayer.actions.setTime(prevStartTime);
    };

    const goToNext = async () => {
        await audioPlayer.actions.unblock();
        if (currentWavepathIndex >= scheduledWavepathStartTimesSeconds.length - 1) {
            audioPlayer.actions.end();
        } else {
            const nextStartTime = scheduledWavepathStartTimesSeconds[currentWavepathIndex + 1];
            audioPlayer.actions.setTime(nextStartTime);
        }
    };

    return {
        isPossibleToGoToPrev,
        isPossibleToGoToNext,
        goToPrev,
        goToNext,
    };
};

const TimelineWithCurrentTimeTracking = ({
    session,
    audioPlayer,
    seek,
}: {
    session: Session;
    audioPlayer: AudioPlayer;
    seek: (wave: Wavepath, offsetSeconds?: number) => Promise<void>;
}) => {
    const [elapsedTimeSecs, setElapsedTimeSecs] = useState(0);
    useInterval(async () => {
        setElapsedTimeSecs((await audioPlayer.getCurrentTimeSecs()) ?? 0);
    }, 1000);
    return (
        <Timeline
            score={session.score}
            variables={session.variableInputs}
            log={undefined}
            session={session}
            waveSelection={STATIC_WAVE_SELECTION}
            setWaveSelection={STATIC_SET_WAVE_SELECTION}
            elapsedTimeMs={elapsedTimeSecs * 1000}
            isScrollable
            phasesAlwaysVisible
            onWaveClick={seek}
        />
    );
};
